exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-calculator-demo-js": () => import("./../../../src/pages/portfolio/calculator-demo.js" /* webpackChunkName: "component---src-pages-portfolio-calculator-demo-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-menu-gen-js": () => import("./../../../src/pages/portfolio/menu-gen.js" /* webpackChunkName: "component---src-pages-portfolio-menu-gen-js" */),
  "component---src-pages-portfolio-pomodoro-timer-demo-js": () => import("./../../../src/pages/portfolio/pomodoro-timer-demo.js" /* webpackChunkName: "component---src-pages-portfolio-pomodoro-timer-demo-js" */),
  "component---src-pages-portfolio-random-quote-generator-js": () => import("./../../../src/pages/portfolio/random-quote-generator.js" /* webpackChunkName: "component---src-pages-portfolio-random-quote-generator-js" */)
}

